import React from "react";
import {Screens, Content, Schedule, Admin, Datas, Touch} from '../../components/svgs/functions'; 

const practicalFunctionsFr = [
  {
    title: 'Gestion d\'écrans',
    description: 'Diffusez vos visuels dans un ou dans des milliers d\'écrans.',
    picto: <Screens />,
  },
  {
      title: 'Types de contenus',
      description: 'Intégrez facilement vos fichiers vidéo, vos photos ou vos visuels animés.',
      picto: <Content />,
  },
  {
      title: 'Horaire',
      description: 'Programmez le bon visuel au bon moment.',
      picto: <Schedule />,
  },
  {
      title: 'Niveaux d’administration',
      description: 'Attribuez le bon niveau d\'accès à vos utilisateurs.',
      picto: <Admin />,
  },
  {
      title: 'Données',
      description: 'Intégration de vos données internes vers un visuel animé.',
      picto: <Datas />,
  },
  {
      title: 'Tactile',
      description: 'Prise en charge de votre projet tactile.',
      picto: <Touch />,
  }
];

const practicalFunctionsEn = [
  {
    title: 'Screen management',
    description: 'Share your visuals on one or thousands of screens.',
    picto: <Screens />,
  },
  {
      title: 'Types of content',
      description: 'Easily incorporate your videos, photos, or animated visuals.',
      picto: <Content />,
  },
  {
      title: 'Schedule',
      description: 'Program the right visual for the right moment.',
      picto: <Schedule />,
  },
  {
      title: 'Management levels',
      description: ' Assign the right management level to your different users.',
      picto: <Admin />,
  },
  {
      title: 'Data',
      description: 'Conversion of your external data to an animated visual.',
      picto: <Datas />,
  },
  {
      title: 'Touch screen',
      description: 'Management of your custom-made touch screen project.',
      picto: <Touch />,
  },
]

export {practicalFunctionsFr, practicalFunctionsEn};
